import { SourceConfig } from '@/models/sources-config';

const sources: SourceConfig[] = [
  {
    id: 'POSTGRESQL',
    type: 'PostgreSQL',
    formComponent: import(/* webpackPrefetch: true */ './forms/DbConnectionForm.vue'),
  },
];

export { sources };
