






























































































import { Vue, Component } from 'vue-property-decorator';
import SourceApi from '@/services/source';
import { sources } from '@/components/sources/sources-config';
import {
  SourceRequest,
  Source,
  EnumSourceType,
  DbConnectionData,
  FileConnectionData,
} from '@/models/source';
import store from '@/store';

const formComponents = sources
  .reduce((obj, item) => Object.assign(obj, { [`${item.id}_form`]: () => item.formComponent }), {});

@Component({
  components: { ...formComponents },
})
export default class Sources extends Vue {
  sourceApi = new SourceApi();

  sourceFields: { text: string, value: string }[] = [
    { text: 'UUID', value: 'UUID' },
    { text: 'name', value: 'name' },
    { text: 'type', value: 'type' },
    { text: 'created by', value: 'created by' },
    { text: 'created at', value: 'created at' },
    { text: '', value: '' },
  ];

  sources: Source[] | null = null;

  sourcesConfig = sources;

  isModalCreateSourceOpen = false;

  isModalDeleteSourceOpen = false;

  sourceToDelete: number | null = null;

  newSource: SourceRequest = {
    name: '',
    type: '' as EnumSourceType,
    connectionData: {} as DbConnectionData | FileConnectionData,
  }

  created(): void {
    this.loadSources();
  }

  loadSources(): void {
    store.dispatch('setLoading', true);
    this.sourceApi.findAll().then((response) => {
      if (response.success) {
        this.sources = response.result;
        console.log('r', this.sources);
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
    }).finally(() => {
      store.dispatch('setLoading', false);
    });
  }

  createSource(): void {
    store.dispatch('setLoading', true);
    this.sourceApi.create(this.newSource).then((response) => {
      if (response.success) {
        console.log('success', response);
        this.isModalCreateSourceOpen = false;
        this.loadSources();
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
      store.dispatch('setLoading', false);
    });
  }

  showDeleteSourceModal(id: number): void {
    this.sourceToDelete = id;
    this.isModalDeleteSourceOpen = true;
  }

  deleteSource(): void {
    store.dispatch('setLoading', true);
    this.sourceApi.remove(this.sourceToDelete!).then((response) => {
      if (response.success) {
        console.log('success', response);
        this.isModalDeleteSourceOpen = false;
        this.loadSources();
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
      store.dispatch('setLoading', false);
    });
  }
}
